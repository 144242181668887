<template>
    <div v-html="html"></div>
</template>
<script>
import base from '../../api/base'
import api from "../../api/ztb";
import { Dialog } from 'vant';
// import { dir } from "console";
export default {
    data() {
        return {
            html: '',
        }
    },
    // created() {
    //     this.fetchVideoPay();
    // },
    methods: {
        fetchVideoPay() {
            // const newTab = window.open();
            let param = {
                orderJg: this.$route.query.jg,
                price: this.$route.query.price,
                userId: this.$route.query.userId,
            };
            console.log('-------------------------003----------------------')
            console.log(param)
            console.log(param.price[0].price, param.price[1].userId, param.price[2].jg)
            api.postWachPay2(param).then(res => {
                console.table(res)
                if(res.status == 200){
                    console.log(res.data)
                    console.log("----------------------微信内H5支付")
                    if(res.data.status == 'ok'){
                        // // 请求微信授权等
                        console.log("本地存储用户订单所用信息！")
                        console.log(res.data)
                        localStorage.setItem("orderJg", param.price[2].jg)
                        localStorage.setItem("price", param.price[0].price)
                        localStorage.setItem("userId", param.price[1].userId)

                        // 传递code给后台
                        window.location = base.WindowLocation
                    }else{
                        console.log("微信订单创建 失败！")
                    }

                }else{
                    console.log("微信订单创建 失败！")
                }
            })
        },
        //发送绑定
        submitFormUser(params) {
            const newTab = window.open();
            api.postAlipay(params).then((res) => {
                console.table(res);
                if (res.status == 200) {
                    // this.$toast("绑定成功！")
                    console.log("=============00000000000000000000000");
                    console.log(res.data)
                    // window.open(innerHtml(res.data), "_blank");
                    // this.sqfk()

                    // window.open(res.data.innerHTML, '_ blank')

                    const div = document.createElement('div');
                    div.innerHTML = res.data; // html code
                    document.body.appendChild(div);
                    document.forms.alipaysubmit.submit();
                } else {
                    console.log("=============33333333333333333333333");
                }
            });
        },
    },
    mounted() {
        this.fetchVideoPay()
    }
}
</script>